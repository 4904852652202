import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import Toast from "vue-toastification";
import { dispatch } from 'rxjs/internal/observable/pairs'

Vue.use(Vuex)

export default new Vuex.Store({
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
        isButtonCheckInDisabled:state=>state.CheckInBotonDisable,
        tiempoRestanteCheckIn(state){
            const Hoy=new Date();
            const Tiempo=Math.max(0,Math.floor((state.TiempoRestanteTimer-Hoy.getTime())/1000));
            const Minutos = Math.floor(Tiempo / 60);
            const Segundos = Tiempo % 60;
            return { Minutos, Segundos };
        },
        tiempoRestanteCheckOut(state){
            const Hoy=new Date();
            const Tiempo=Math.max(0,Math.floor((state.TiempoRestanteCheckOutTimer-Hoy.getTime())/1000));
            const Minutos = Math.floor(Tiempo / 60);
            const Segundos = Tiempo % 60;
            return { Minutos, Segundos };
        }
    },
    state: {
        status: '',
        token: sessionStorage.getItem('token_user') || '',
        user: JSON.parse(sessionStorage.getItem('user')) || {},
        ruta: sessionStorage.getItem('ruta') || '',
        zona: sessionStorage.getItem('ZonaHoraria') || '',
		timeDespacho: '',
        timeChatNotification: '',
        CheckInBotonDisable:false,
        CheckInBotonTimer:null,
        TiempoRestanteTimer:null,
        TiempoRestanteCheckOutTimer:null,
        CheckInEstatus:'',
        TimeOutCheckOut:''
    },
    mutations: {
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, { token, user, ruta, zona }) {
            state.status = 'success'
            state.token  = token
            state.user   = user
            state.ruta   = ruta
            state.zona   = zona
        },
        auth_error(state) {
            state.status = 'error'
        },

        logout(state) {
            state.status    = ''
            state.token     = ''
            state.ruta      = ''
            state.zona      = ''
			state.timeDespacho = ''
			state.timeChatNotification = ''
        },

		stopTimerDespacho(state){
			state.timeDespacho = ''
		},

        stopTimerChatNotification(state){
            state.timeChatNotification = ''
        },
        DisableBotonCheckIn(state){
            state.CheckInBotonDisable=true;
        },
        EnableBotonCheckIn(state){
            state.CheckInBotonDisable=false;
        },
        SetCheckInBotonTimer(state,timer){
            state.CheckInBotonTimer=timer;
        },
        ClearCheckInBotonTimer(state){
            state.CheckInBotonTimer=null;
        },
        SetTiempoRestanteTimer(state,timer){
            state.TiempoRestanteTimer=timer;
        },
        SetTiempoRestanteCheckOutTimer(state,timer){
            state.TiempoRestanteCheckOutTimer=timer;
        },
        ClearTiempoRestanteCheckOutTimer(){
            state.TiempoRestanteCheckOutTimer=null;
        }
    },
    actions: {
        login({ commit }, usuario) {
            let token     = usuario.token;
            const user    = usuario.usuario;
            const ruta    = usuario.ruta;
            const rutaE   = usuario.rutaE;
            const cliente = usuario.cliente;
            const empresa = usuario.empresa;
            const zona    = usuario.Zona
            Vue.prototype.$http.defaults.headers.common['Authorization'] = token;

            sessionStorage.setItem('token_user', token);
            sessionStorage.setItem('user', JSON.stringify(user));
            sessionStorage.setItem('clientelog', JSON.stringify(cliente));
            sessionStorage.setItem('ruta', ruta);
            sessionStorage.setItem('rutaE', rutaE);
            sessionStorage.setItem('empresa', JSON.stringify(empresa));
            sessionStorage.setItem('ZonaHoraria', zona);

            commit('auth_success', { token, user, ruta, zona});
        },

        logout({ commit })
        {
            return new Promise((resolve, reject) => {
                commit('logout');
                commit('stopTimerDespacho');
                commit('stopTimerChatNotification');

                sessionStorage.removeItem('token_user');
                sessionStorage.removeItem('user');
                resolve();
            });
        },
        IniciarTempCheckIn({commit,state}){
            const Hoy=new Date();
            const FinTiempo = new Date(Hoy.getTime() + 120 * 1000);
            commit('SetTiempoRestanteTimer',FinTiempo);
            const timer=setTimeout(() => {
                commit('EnableBotonCheckIn');
                commit('ClearCheckInBotonTimer');
            }, 120000);
            commit('SetCheckInBotonTimer', timer);
        },
        IniciarTempCheckOut({commit,state}){
            let User=JSON.parse(sessionStorage.getItem('user'));
            const Hoy=new Date();
            let FechaSalida=new Date();
            FechaSalida.setHours(Number(User.HoraSalida),Number(User.MinutoSalida),0,0);
            if (Number(User.HoraEntrada)>Number(User.HoraSalida)) {
                FechaSalida.setDate(FechaSalida.getDate()+1);
            }
            const Diferencia=FechaSalida.getTime()-Hoy.getTime();
            const FinTiempo=new Date(Hoy.getTime()+Diferencia);
            commit('SetTiempoRestanteCheckOutTimer',FinTiempo);
            const timer=setTimeout(() => {
                commit('ClearTiempoRestanteCheckOutTimer');
            }, Diferencia);
        },
        ActualizarCheckIn({commit,dispatch}){
            commit('DisableBotonCheckIn');
            dispatch('IniciarTempCheckIn');
        },
        LimpiarTempCheckIn({state}){
            if (state.CheckInBotonTimer) {
                clearTimeout(state.CheckInBotonTimer);
            }
        },
        AvisoCheckOut(){
            // this.$toast.warning('a');
        }
    },
    modules: {}
})
