<template>
	<div>
		<div class="form-row">

			<div class="col-lg-4 form-group">
				<label>Nombre </label>
				<input type="text" placeholder="Ingresa Nombre" v-model="sucursal.Nombre" class="form-control" />
				<Cvalidation v-if="this.errorvalidacion.Nombre" :Mensaje="'Campo olbigatorio'"></Cvalidation>

			</div>

			<div class="col-lg-8 form-group">
				<label>Dirección </label>
				<input type="text" placeholder="Ingresa Direccion" v-model="sucursal.Direccion" class="form-control" />
				<Cvalidation v-if="this.errorvalidacion.Direccion" :Mensaje="'Campo olbigatorio'"></Cvalidation>

			</div>

		</div>

		<div class="form-row">

			<div class="col-lg-4 form-group">
				<label>Teléfono </label>
				<input type="text" placeholder="Ingresa Teléfono" v-model="sucursal.Telefono" class="form-control" />
				<Cvalidation v-if="this.errorvalidacion.Telefono" :Mensaje="'Campo olbigatorio'"></Cvalidation>

			</div>

			<div class="col-lg-4 form-group">
				<label>Correo </label>
				<input type="text" placeholder="Ingresa Correo" v-model="sucursal.Correo" class="form-control" />
				<Cvalidation v-if="this.errorvalidacion.Correo" :Mensaje="'Campo olbigatorio'"></Cvalidation>

			</div>

			<div class="col-lg-4 form-group">
				<label>Estado </label>
				<input type="text" placeholder="Ingresa Estado" v-model="sucursal.Estado" class="form-control" />
				<Cvalidation v-if="this.errorvalidacion.Estado" :Mensaje="'Campo olbigatorio'"></Cvalidation>

			</div>

		</div>

		<div class="form-row">

			<div class="col-lg-4 form-group">
				<label>Ciudad </label>
				<input type="text" placeholder="Ingresa Ciudad" v-model="sucursal.Ciudad" class="form-control" />
				<Cvalidation v-if="this.errorvalidacion.Ciudad" :Mensaje="'Campo olbigatorio'"></Cvalidation>

			</div>

			<div class="col-lg-4 form-group">
				<label>Código Postal </label>
				<input type="text" placeholder="Ingresa Código Postal" v-model="sucursal.CP" class="form-control" />
				<Cvalidation v-if="this.errorvalidacion.CP" :Mensaje="'Campo olbigatorio'"></Cvalidation>

			</div>

		</div>

		<div class="form-group form-row mt-2">
			<div class="col-md-12 col-lg-12">
				<h4 class="color-01">Datos Publicos</h4>
				<hr>
			</div>
		</div>


		<div class="form-row">

			<div class="col-lg-4 form-group">
				<label>Nombre </label>
				<input type="text" placeholder="Ingresa Nombre" v-model="sucursal.NombrePublico" class="form-control" maxlength="200"/>
				<Cvalidation v-if="this.errorvalidacion.Nombre" :Mensaje="'Campo olbigatorio'"></Cvalidation>

			</div>

			<div class="col-lg-4 form-group">
				<label>Teléfono </label>
				<input type="text" placeholder="Ingresa Teléfono" v-model="sucursal.TelefonoPublico" class="form-control"  maxlength="15" />
				<Cvalidation v-if="this.errorvalidacion.Telefono" :Mensaje="'Campo olbigatorio'"></Cvalidation>

			</div>

			<div class="col-lg-4 form-group">
				<label>Correo </label>
				<input type="text" placeholder="Ingresa Correo" v-model="sucursal.CorreoPublico" class="form-control" maxlength="200" />
				<Cvalidation v-if="this.errorvalidacion.Correo" :Mensaje="'Campo olbigatorio'"></Cvalidation>

			</div>


		</div>

		<div class="form-row">
			<div class="col-lg-12 form-group">
				<label>Dirección </label>
				<textarea class="form-control" placeholder="Ingresa Direccion" v-model="sucursal.DireccionPublico" maxlength="300"></textarea>
				<Cvalidation v-if="this.errorvalidacion.Direccion" :Mensaje="'Campo olbigatorio'"></Cvalidation>

			</div>

		</div>

		<div v-if="ShowMap" class="form-row">
			<div class="col-lg-12 form-group">
				<label for="">Selecciona tu Ubicación</label>
				<Search :oLatLng="oLatLng" :Input="true"></Search>
			</div>
		</div>
	</div>
</template>

<script>
//El props Id es cuando no es modal y se mando con props
//El export de btnsave es por si no se usa el modal
import Cbtnsave from '@/components/Cbtnsave.vue'
import Cvalidation from "../../../components/Cvalidation";

export default {
    name:'Form',
    props:['IdSucursal'],
	components:{
		Cbtnsave,
		Cvalidation
	},
    data() {
        return {
			Modal:true,//Sirve pra los botones de guardado
            FormName:'sucursal',//Sirve para donde va regresar
			sucursal:{
				IdSucursal: 0,
				Nombre: "",
				Telefono: "",
				Direccion: "",
				Correo: "",
				Ciudad: "",
    			Estado: "",
        		CP: "",
        		IdEmpresa: "",
				LatSuc:"",
				LongSuc:"",

				NombrePublico:'',
				DireccionPublico:'',
				TelefonoPublico:'',
				CorreoPublico:''

            },
            urlApi:"sucursal/recovery",
			errorvalidacion:[],
			oLatLng:{
				Lat:0,
				Lng:0
			},
			ShowMap:false,
			markers:[],
			rutatrab:''
        }
    },

    methods : {
		async Guardar() {
			this.sucursal.LatSuc=this.oLatLng.Lat;
			this.sucursal.LongSuc=this.oLatLng.Lng;
			await this.$http.post('sucursal/updateprofile', this.sucursal, {
				headers:{

				}
			}).then( (res) => {
				this.$swal({
					toast: true,
					position: 'top-end',
					showConfirmButton: true,
					timer: 3000,
					type: 'succes',
					title: 'Informacion Guardada'
				});

				if (this.Id === undefined){
					$('#ModalForm').modal('hide');
					this.bus.$emit('List');

				} else{
					this.$router.push({name:this.FormName});
				}

			}).catch( err => {
				console.log('Error');
			});
		},

		Limpiar() {
			this.ShowMap=false;
			this.sucursal = {
				IdSucursal: 0,
				Nombre: "",
				Telefono: "",
				Direccion: "",
				Correo: "",
				Ciudad: "",
				Estado: "",
				CP: "",
				IdEmpresa: "",
				LatSuc:"",
				LongSuc:"",

				NombrePublico:'',
				DireccionPublico:'',
				TelefonoPublico:'',
				CorreoPublico:''
			};
        },

        get_one() {
            this.$http.get(this.urlApi, {
				params:{
					IdSucursal: this.sucursal.IdSucursal
				}
			}).then( (res) => {
				this.sucursal = res.data.data.sucursal;
				if (this.sucursal.LatSuc=='' || this.sucursal.LongSuc=='') {
					this.bus.$emit('actualCordenadas2');
				}
				else{
					this.oLatLng.Lat=parseFloat(this.sucursal.LatSuc);
					this.oLatLng.Lng=parseFloat(this.sucursal.LongSuc);
				}
				this.ShowMap=true;
            });
        },
		async getUbicaciones() {
			await this.$http
				.get(
					// 'ubicacionmapa/get',
					"ubicacionmapa2/get",
					{
						params: {}
					}
				)
				.then(res => {
					this.rutatrab = res.data.data.ruta;
					this.rutaclientes=res.data.data.rutaclientes;
					this.markers = [];

					res.data.data.ubicaciones.forEach(element => {
						this.markers.push({
							position: {
								lat: parseFloat(element.lat),
								lng: parseFloat(element.lng)
							},

							//Nuevo
							datos: {
								Tipo: element.Tipo,
								Tecnico: element.Nombre,
								Cliente: element.Cliente,
								Servicio: element.Folio,
								TipoServicio: element.Concepto,
								FechaI: element.Fecha_I,
								FechaF: element.Fecha_F,
								HoraI: element.HoraInicio,
								HoraF: element.HoraFin,
								Estatus: element.Estatus,
								Foto2: element.Foto2
							}
						});
					});
					// sessionStorage.setItem("rutaMapa", this.rutatrab);
				});
		},
    },
    created() {

        this.bus.$off('Save');
        this.bus.$off('Nuevo');

		this.Limpiar();

        this.bus.$on('Save',()=> {
           this.Guardar();
        });

        //Este es para modal
        this.bus.$on('Nuevo',(data,Id)=> {


            if (Id>0) {
				// this.getUbicaciones();
				this.sucursal.IdSucursal= Id;
				this.get_one();
            }

        });


    }
}
</script>
