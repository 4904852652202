<template>
    <div>
        <Clist :ShowFilas="false" :pShowBtnAdd="false" :regresar="false" :Nombre="NameList" :Pag="Pag" :Total="TotalPagina" @FiltrarC="Lista" :Filtro="Filtro" :pConfigLoad="ConfigLoad" :ShowBusqueda="false">
            <template slot="botonextra">
                <button @click="Imprimir" type="button" class="btn btn-pink mb-2 mr-1">
					<i class="fa fa-print"></i> Imprimir
				</button>
            </template>
            <template slot="Filtros">
                <div class="form-group mr-2">
                    <select style="width: 150px;" @change="Lista"  v-model="Filtro.IdTrabajador"  class="form-control">
                        <option :value="''">--Personal--</option>
                        <option v-for="(item, index) in ListaTrabajadores" :key="index" :value="item.IdTrabajador">{{item.Nombre}}</option>
                    </select>
                </div>
                <div class="form-group mr-2">
                    <select style="width: 150px;" @change="Lista"  v-model="Filtro.Estatus"  class="form-control">
                        <option value="">--Estatus--</option>
                        <option value="Retardo">Retardo</option>
                        <option value="Falta">Falta</option>
                        <option value="HorasExtra">Horas Extra</option>
                    </select>
                </div>
                <div class="form-group mr-2">
                    <v-date-picker
                    mode='range'
                    v-model='rangeDate'
                    @input="Lista"
                    :input-props='{
                    class: "form-control   calendar",
                    placeholder: "Selecciona un rango de fecha para buscar",
                    readonly: true
                    }'/>
                </div>
            </template>
            <template slot="header">
                <tr>
                    <th></th>
					<th></th>
					<th class="text-center" colspan="2">Check</th>
                    <th class="text-center" colspan="2">Comida</th>
                    <th class="text-center" colspan="2">Evento 1</th>
                    <th class="text-center" colspan="2">Evento 2</th>
                    <th class="text-center" colspan="2">Evento 3</th>
					<th></th>
					<th></th>
                </tr>
                <tr>
                    <th>Fecha</th>
					<th>Nombre del Personal</th>
					<th class="text-center">In</th>
                    <th class="text-center">Out</th>
                    <th class="text-center">In</th>
                    <th class="text-center">Out</th>
                    <th class="text-center">In</th>
                    <th class="text-center">Out</th>
                    <th class="text-center">In</th>
                    <th class="text-center">Out</th>
                    <th class="text-center">In</th>
                    <th class="text-center">Out</th>
					<th>Estatus</th>
					<th>Acciones</th>
                </tr>
            </template>
            <template slot="body">
                <tr v-for="(item, index) in ListaCheckIn" :key="index">
                    <td>{{ $getCleanDate(item.Fecha,false) }}</td>
                    <td>{{ item.Trabajador }}</td>
                    <td class="text-center">{{ FormatearFecha(item.checkin) }}</td>
                    <td class="text-center">{{ FormatearFecha(item.checkout) }}</td>
                    <td class="text-center">{{ FormatearFecha(item.comidacheckin) }}</td>
                    <td class="text-center">{{ FormatearFecha(item.comidacheckout) }}</td>
                    <td class="text-center">{{ FormatearFecha(item.evento1checkin) }}</td>
                    <td class="text-center">{{ FormatearFecha(item.evento1checkout) }}</td>
                    <td class="text-center">{{ FormatearFecha(item.evento2checkin) }}</td>
                    <td class="text-center">{{ FormatearFecha(item.evento2checkout) }}</td>
                    <td class="text-center">{{ FormatearFecha(item.evento3checkin) }}</td>
                    <td class="text-center">{{ FormatearFecha(item.evento3checkout) }}</td>
                    <td><div v-b-tooltip.hover :title="item.estatus" :class="DefinirClaseCheck(item.estatus) +' m-0'"></div></td>
                    <td>
                        <Cbtnaccion :isModal="false" :Id="item.IdTrabajador" :IrA="'checkindetalle'" :pShowButtonDelete="false" :pShowButtonEdit="false">
                            <template slot="btnaccion">
                                <button v-b-tooltip.hover.top class="btn-icon" title="Ver Más" @click="IrADetalle(item.IdTrabajador)">
                                    <i class="fa fa-eye"></i>
                                </button>
                            </template>
                        </Cbtnaccion>
                    </td>
                </tr>
            </template>
        </Clist>
    </div>
</template>

<script>
import Clist from "@/components/Clist.vue";
import Cbtnaccion from "@/components/Cbtnaccion.vue";
export default {
    name:"checkin",
    components:{Clist,Cbtnaccion},
    data() {
        return {
            NameList: "Administración de Personal",
            TotalPagina: 2,
			Pag: 0,
            Filtro:{
                Entrada: 20,
				Nombre: "",
				Placeholder: "Nombre..",
				TotalItem: 0,
				Pagina: 1,
                IdTrabajador:"",
                Estatus:""
            },
            ConfigLoad:{
				ShowLoader:true,
				ClassLoad:true,
			},
            ListaCheckIn:[],
            rangeDate:{},
            ListaTrabajadores:[],
        }
    },
    methods: {
        async Lista(){
            this.ConfigLoad.ShowLoader=true;
            this.$http.get('checkin/get',{
                params:{
                    Entrada:this.Filtro.Entrada,
                    pag:this.Filtro.Pagina,
                    Fecha_I:this.rangeDate.start,
                    Fecha_F:this.rangeDate.end,
                    IdTrabajador:this.Filtro.IdTrabajador,
                    Estatus:this.Filtro.Estatus,
                }
            }).then((res)=>{
                this.ListaCheckIn=res.data.data.tabla;
                this.Filtro.Entrada=res.data.data.pagination.PageSize;
                this.Filtro.TotalItem=res.data.data.pagination.TotalItems;
            }).finally(()=>{
				this.ConfigLoad.ShowLoader = false;
			});
        },
        GetListTrabajador(){
            this.$http.get(
            'trabajador/get',
            {
                params:{RegEstatus:'A'}
            }
            ).then( (res) => {
                this.ListaTrabajadores=res.data.data.trabajador;
            });
        },
        FormatearFecha(Fecha){
            if (Fecha!='-') {
                let FechaObj=new Date(Fecha);
                let Hora=FechaObj.getHours();
                let Minuto=FechaObj.getMinutes() < 10 ? '0'+FechaObj.getMinutes() : FechaObj.getMinutes();
                return Hora+':'+Minuto;
            }
            else{
                return '-';
            }
        },
        DefinirClaseCheck(Status){
            let Clase="";
            switch (Status) {
                case "Puntual":
                    Clase="circuloStatusOperando";
                    break;
                case "Retardo":
                    Clase="circuloStatusObservacion";
                    break;
                case "Falta":
                    Clase="circuloStatusFueraServicio";
                    break;
                case "Horas Extra":
                    Clase="circuloStatusAzul";
                    break;
                case "Hora Extra":
                    Clase="circuloStatusAzul";
                    break;
                case "Vacaciones":
                    Clase="circuloStatusServicio";
                    break;
                default:
                    Clase="circuloStatusDefault";
                    break;
            }
            return Clase;
        },
        Imprimir(){
            let formData=new FormData();
            formData.set("Tabla",JSON.stringify(this.ListaCheckIn));
            this.$http.post('checkinpdf',formData,{responseType: 'blob'}).then((res)=>{
                let pdfContent = res.data;
                let file = new Blob([pdfContent], { type: 'application/pdf' });
                let fileUrl = URL.createObjectURL(file);

                window.open(fileUrl);
            });
        },
        IrADetalle(Index){
            this.$router.push({name:"checkindetalle",params:{Id:Index}});
        }
    },
    created() {
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        var firstDay = new Date();
        var lastDay = new Date();
        this.rangeDate={
            start:firstDay,
            end:lastDay
        }
    },
    mounted() {
        this.GetListTrabajador();
        this.Lista();
    },
}
</script>

<style>

</style>