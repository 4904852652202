<template>
	<div class="container-fluid">
		<Clist
			:regresar="regresar"
			:Nombre="Titulo"
			@FiltrarC="Lista"
			:Filtro="Filtro"
			:isModal="EsModal"
			:pShowBtnAdd="false"
			:pConfigLoad="ConfigLoad"
			:isHistorialEquipo="HistorialEquipo"
			:ShowPager="ShowPager"
			>
			<template slot="DatosEquipo">
				<input type="hidden" :value="setLocation">
				<div class="col-lg-12 mb-3">
					<div class="row">
						<div class="col-lg-2">
							<div class="avatar-upload-histequipo">
								<div v-if="RolUsuario!='Monitoreo' && IdPerfil!=0" class="avatar-edit">
									<input id="file" @change="CambiarImagenEquipo($event)" ref="file" type="file" name="file" accept=".png, .jpg, .jpeg">
									<label for="file"></label>
								</div>
								<div class="avatar-edit2">
									<button type="button" data-backdrop="static"
									data-toggle="modal"
									data-target="#imagen" @click="ZoomImagen(Equipo.ImagenEquipo,1)">
										<i class="fas fa-search"></i>
									</button>
								</div>
								<div v-if="BndImagenEquipo!=0" class="avatar-edit3">
									<button type="button" @click="GuardarImagenEquipo">
										<i class="fas fa-save"></i>
									</button>
								</div>
								<div class="avatar-preview">
									<div id="imagePreview" :style="'background-image: url('+Equipo.ImagenEquipo+');'" :src="Equipo.ImagenEquipo">
									</div>
								</div>
							</div>
							<div v-if="BndImagenEquipo!=0">
								<p class="text-danger text-leyenda">Aún no sube la imagen, presione guardar para subirla</p>
							</div>
						</div>
						<div class="col-lg-2">
							<div class="avatar-upload-histequipo" id="especificaciones">
								<div v-if="RolUsuario!='Monitoreo' && IdPerfil!=0" class="avatar-edit">
									<input id="file2" @change="CambiarImagenEspecificaciones($event)" ref="file2" type="file" name="file2" accept=".png, .jpg, .jpeg">
									<label for="file2"></label>
								</div>
								<div class="avatar-edit2">
									<button type="button" data-backdrop="static"
									data-toggle="modal"
									data-target="#imagen" @click="ZoomImagen(Equipo.ImagenEspecificaciones,2)">
										<i class="fas fa-search"></i>
									</button>
								</div>
								<div class="avatar-preview">
									<div id="ImagePreviewEspecificaciones" :style="'background-image: url('+Equipo.ImagenEspecificaciones+');'" :src="Equipo.ImagenEspecificaciones">
									</div>
								</div>
								<div v-if="BndImagenEspecificaciones!=0" class="avatar-edit3">
									<button type="button" @click="GuardarImagenEspecificaciones">
										<i class="fas fa-save"></i>
									</button>
								</div>
							</div>
							<div class="position-relative" style="right:5px" v-if="BndImagenEspecificaciones!=0">
								<blockquote><p class="text-danger text-leyenda">Aún no sube la imagen, presione guardar para subirla</p></blockquote>
							</div>
						</div>
						<div class="col-lg-5">
							<div class="row">
								<div class="col-lg-7 m-0 p-0">
									<span class="titulosmonitoreo">Cliente: </span> <span class="text-info titulosmonitoreo">{{ Cliente.Nombre }}</span>
									<br>
									<span class="titulosmonitoreo">Propiedad: </span> <span class="text-info titulosmonitoreo">{{ ClienteSucursal.Nombre }}</span>
									<br>
									<span class="titulosmonitoreo">Nombre del Equipo: </span> <span class="text-info titulosmonitoreo">{{ Equipo.Nequipo }}</span>
									<br>
									<span class="titulosmonitoreo">Tipo Unidad:</span> <span class="text-info titulosmonitoreo">{{ Equipo.Unidad }}</span>
									<br>
									<span class="titulosmonitoreo">Marca: </span> <span class="text-info titulosmonitoreo">{{ Equipo.Marca }}</span>
									<br>
									<span class="titulosmonitoreo">Modelo:</span> <span class="text-info titulosmonitoreo">{{ Equipo.Modelo }}</span>
									<br>
									<span class="titulosmonitoreo">Número de Serie:</span> <span class="text-info titulosmonitoreo">{{ Equipo.Serie }}</span>
									<br>
									<!-- <span class="titulosmonitoreo">Origen:</span> <span class="text-info">{{ Equipo.Origen }}</span>
									<br> -->
									<span class="titulosmonitoreo">Año de Fabricación:</span> <span class="text-info titulosmonitoreo">{{ Equipo.Ano }}</span>
									<br>
								</div>
								<div class="col-lg-5 m-0 p-0">
									<!-- <span class="titulosmonitoreo">Nombre: </span> <span class="text-info">{{ Equipo.Nequipo }}</span>
									<br> -->
									<span class="titulosmonitoreo">Ubicación Física: </span> <span class="text-info titulosmonitoreo">{{ Equipo.Ubicacion }}</span>
									<br>
									<span class="titulosmonitoreo">Código por Cliente: </span> <span class="text-info titulosmonitoreo">{{ Equipo.ActivoCliente }}</span>
							
									
								</div>
							</div>
						</div>
						<div class="col-lg-3">
							<div class="row">
								<div class="col-lg-12">
									<div class="d-flex justify-content-end">
										<button class="btn btn-pink mr-2" @click="DescargarQR(Equipo)">Descargar QR</button>
										<button v-if="RolUsuario!='Monitoreo' && IdPerfil!=0" class="btn btn-01 mr-2" @click="EditarEquipo(Equipo.IdEquipo)" data-toggle="modal" data-target="#ModalForm">Editar</button>
										<button v-if="RolUsuario!='Monitoreo' && IdPerfil!=0" class="btn btn-danger" @click="BorrarEquipo(Equipo.IdEquipo)">Eliminar</button>
									</div>
									
								</div>
							</div>
							<div class="row mt-5">
								<div v-if="!updEnProceso" class="col-lg-12">
									<div class="d-flex justify-content-end">
										<!-- <div class="circular_shadow m-0 borde-gris">
											<img class="img-emp" :src="rutaIcoEmp+ClienteSucursal.IdIconoEmp" alt="">
										</div> -->
										<div :class="Equipo.Status =='Fuera de Servicio' ?'circular_shadow m-0 ml-3 borde-rojo2' :Equipo.Status=='En Servicio' ?'circular_shadow m-0 ml-3 borde-morado' :Equipo.Status=='En Observacion' ?'circular_shadow m-0 ml-3 borde-amarillo':Equipo.Status=='Operando'?'circular_shadow m-0 ml-3 borde-verde ': 'circular_shadow m-0 ml-3 borde-gris'">
											<svg-injector v-show="updEnProceso==false" :class-name="Equipo.Status == 'Fuera de Servicio' ? 'svg-inject iconic-signal-weak' : Equipo.Status=='En Servicio' ? 'svg-inject iconic-signal-current' : Equipo.Status=='En Observacion' ? 'svg-inject iconic-signal-medium' : Equipo.Status=='Operando' ? 'svg-inject iconic-signal-strong ' : 'svg-inject iconic-signal-none'" :src="Equipo.ImgSvg"></svg-injector>
										</div>
										<!-- <div class="d-flex"> -->
											<img class="ml-3" width="36%" :src="Equipo.RutaQr" alt="">
										<!-- </div> -->
									</div>
								</div>
							</div>
							<div class="row mt-2 ml-5 justify-content-center">
								<div class="text-center">
									<div class="d-flex align-items-center" style="flex-direction:column">
										<div class="text-center">
											<h2 class="bold">ID Equipo</h2> <h2 class="text-info bold">{{ IdActivo }}</h2>
										</div>
									</div>
									
								</div>
							</div>
							
						</div>
					</div>
				</div>

				<div class="row mb-2">
					<div class="col-lg-12">
						<div class="form-inline">
							<button @click="CambiarPestania('Historia')" type="button" :class="Botones.Historia ? 'btn btn-06 text-info bold' : 'btn btn-06 text-dark bold'">Historia Servicios</button>
							<button @click="CambiarPestania('Observaciones')" type="button" :class="Botones.Observaciones ? 'btn btn-06 text-info bold' : 'btn btn-06 text-dark bold'">Observaciones Críticas</button>
							<button @click="CambiarPestania('Documentos')" type="button" :class="Botones.Documentos ? 'btn btn-06 text-info bold' : 'btn btn-06 text-dark bold'">Documentos</button>
							<button v-if="RolUsuario!='Monitoreo' && IdPerfil!=0" @click="CambiarPestania('Refacciones')" type="button" :class="Botones.Refacciones ? 'btn btn-06 text-info bold' : 'btn btn-06 text-dark bold'">Refacciones</button>
							<button v-else class="btn btn-06 text-gray-300 bold" disabled>Refacciones</button>
							<button v-if="RolUsuario!='Monitoreo' && IdPerfil!=0" @click="CambiarPestania('Programa')" type="button" :class="Botones.Programa ? 'btn btn-06 text-info bold' : 'btn btn-06 text-dark bold'">Programa Mantenimiento</button>
							<button v-else class="btn btn-06 text-gray-300 bold" disabled>Programa  Mantenimiento</button>
							<button @click="CambiarPestania('Ubicacion')" type="button" :class="Botones.Ubicacion ? 'btn btn-06 text-info bold' : 'btn btn-06 text-dark bold'">Ubicación</button>
						</div>
					</div>
				</div>
			</template>
			<!-- <div v-if="Botones.Historia"> -->
				<!-- BLOQUE DEL HISTORIAL DE SERVICIOS -->
				<template v-if="Botones.Historia" slot="header">
					<tr>
						<th>Servicio</th>
						<th>Fecha</th>
						<th>Tipo</th>
						<th>Propiedad</th>
						<th>Responsable</th>
						<th>Observaciones</th>
						<th class="text-center">Estatus Equipo</th>
						<th>Acciones</th>
						<th v-if="RolUsuario!='Monitoreo' && IdPerfil!=0">
							<button
								@click="ServicioManual"
								v-b-tooltip.hover.left
								type="button"
								data-backdrop="static"
								data-toggle="modal"
								data-target="#Servicios"
								data-keyboard="false"
								class="mr-2 btn-obs"
								title="Agregar"
							>
								<i
								class="fas fa-plus"></i>
							</button>
						</th>
					</tr>
				</template>
				<template v-if="Botones.Historia" slot="body">
					<tr v-for="(lista, key, index) in ListaHistorial" :key="index">
						<td class="tw-1">{{ MostrarFolio(lista) }}</td>
						<td class="tw-1"><i class="fas fa-calendar-day"></i> {{ $getCleanDate(lista.Fecha_I,false) }}</td>
						<td class="tw-1">{{ lista.Servicio }}</td>
						<td class="tw-1">{{ lista.Sucursal }}</td>
						<td class="tw-2">{{ lista.Trabajador }}</td>
						<td class="tw-2">{{ $limitCharacters(lista.ComentarioFin,90) }}</td>
						<td class="tw-2 text-center">
							<div v-b-tooltip.hover :title="lista.EstatusEquipo=='No Revisado' ? 'Desconocido' : lista.EstatusEquipo" :class="DefinirClaseActivo(lista.EstatusEquipo)"></div>
						</td>
						<td class="tw-2">
							<button
								@click="(DevolverServicioCompleto(lista))"
								v-b-tooltip.hover.left
								type="button"
								data-backdrop="static"
								data-keyboard="false"
								data-toggle="modal"
								data-target="#Servicios"
								class="btn-icon mr-2"
								title="Ver Detalle"
							>
								<i :class="RolUsuario!='Monitoreo' && IdPerfil!=0 ? 'fas fa-pencil fa-fw-m' : 'fas fa-eye fa-fw-m'"></i>
							</button>
							<button
								v-if="lista.Indicador==null || (lista.ReporteServicio!='' && lista.ReporteServicio!=null)"
								v-b-tooltip.hover.left
								@click="lista.Indicador==null ? DescargarReporte(lista.IdServicio) : VerPDFDocumentos(RutaReporteManual+lista.ReporteServicio)"
								type="button"
								data-backdrop="static"
								data-keyboard="false"
								class="btn-icon mr-2"
								title="Ver Reporte de Servicio"
							>
								<i 
								:id="'pdfOrden_'+lista.IdServicio"
								class="fas fa-file-pdf fa-fw-m"></i>
							</button>
							<button
								v-if="lista.Indicador==null || (lista.ReporteEvidencia!='' && lista.ReporteEvidencia!=null)"
								v-b-tooltip.hover.left
								@click="lista.Indicador==null ? DescargarEvidencia(lista.IdServicio) : VerPDFDocumentos(RutaEvidenciaManual+lista.ReporteEvidencia)"
								type="button"
								data-backdrop="static"
								data-keyboard="false"
								class="btn-icon mr-2"
								title="Ver Reporte Fotográfico"
							>
								<i 
								:id="'pdfEvidencia_'+lista.IdServicio"
								class="fas fa-file-pdf fa-fw-m"></i>
							</button>
							<button v-if="lista.Indicador!=null && RolUsuario!='Monitoreo' && IdPerfil!=0"
							v-b-tooltip.hover.left
								@click="BorrarServicioManual(lista.IdServicio)"
								type="button"
								data-backdrop="static"
								data-keyboard="false"
								class="btn-icon-02 mr-2"
								title="Eliminar"
							>
							<i class="fas fa-trash"></i>
							</button>
						</td>
						<td class="tw-1" v-if="RolUsuario!='Monitoreo' && IdPerfil!=0"></td>
					</tr>
				</template>
			<!-- </div> -->
			<!-- BLOQUE DE OBSERVACIONES CRITICAS -->
			<template v-if="Botones.Observaciones" slot="header">
				<tr>
					<th>Observaciones Críticas</th>
					<th class="text-right">Fecha de registro</th>
					<th class="text-right">Acciones</th>
					<th class="text-right">
						<button
							v-if="RolUsuario!='Monitoreo' && IdPerfil!=0"
							v-b-tooltip.hover.left
							@click="EditarObservaciones(Observacion1)"
							type="button"
							data-backdrop="static"
							data-toggle="modal"
							data-target="#observaciones"
							data-keyboard="false"
							class="mr-2 btn-obs"
							title="Agregar"
							>
							<i
							class="fas fa-plus"></i>
						</button>
					</th>
				</tr>
			</template>
			<template v-if="Botones.Observaciones" slot="body">
				<tr v-for="lista,key,index in Observacionesequipo" :key="index">
					<td>{{ $limitCharacters(lista.Observacion,120) }}</td>
					<td class="text-right"><i class="fas fa-calendar-day"></i> {{ $getCleanDate(lista.FechaReg,false) }}</td>
					<td class="text-right">
						<button
							v-b-tooltip.hover.left
							@click="EditarObservaciones(lista)"
							type="button"
							data-backdrop="static"
							data-toggle="modal"
							data-target="#observaciones"
							data-keyboard="false"
							class="mr-2 btn-icon"
							:title=" RolUsuario!='Monitoreo' && IdPerfil!=0 ? 'Editar' : 'Ver mas'"
							>
							<i
							:class="RolUsuario!='Monitoreo' && IdPerfil!=0 ? 'fa fa-pencil' : 'fa fa-eye'"></i>
						</button>

						<button
							v-if="RolUsuario!='Monitoreo' && IdPerfil!=0"
							v-b-tooltip.hover.left
							@click="Borrar(lista.IdObservacion)"
							type="button"
							data-backdrop="static"
							data-keyboard="false"
							class="btn-icon-02 mr-2"
							title="Borrar"
							>
							<i 
							:id="'pdfEvidencia_'+1"
							class="fas fa-trash"></i>
						</button>
					</td>
					<td></td>
				</tr>
				<CSinRegistros :pContIF="Observacionesequipo.length" :pColspan="4" ></CSinRegistros>
			</template>
			<!-- BLOQUE DE DOCUMENTOS -->
			<template v-if="Botones.Documentos" slot="header">
				<tr>
					<th>Título</th>
					<th>Descripción</th>
					<th class="text-right">Fecha de Registro</th>
					<th class="text-center">Archivo</th>
					<!-- <th class="text-right">
						<button
							v-if="RolUsuario!='Monitoreo' && IdPerfil!=0 && Documentos.length<5"
							v-b-tooltip.hover.left
							@click="EditarDocumentos(DocumentoVacio,0)"
							type="button"
							data-backdrop="static"
							data-toggle="modal"
							data-target="#documentos"
							data-keyboard="false"
							class="mr-2 btn-obs"
							title="Agregar"
							>
							<i
							class="fas fa-plus"></i>
						</button>
					</th> -->
				</tr>
			</template>
			<template v-if="Botones.Documentos" slot="body">
				<tr v-for="lista,key,index in Documentos" :key="index">
					<td>
						{{ lista.Titulo }}
					</td>
					<td>{{ lista.Descripcion }}</td>
					<td class="text-right">
						<i class="fa fa-calendar"></i> {{ $getCleanDate(lista.FechaAlta,false) }}
					</td>
					<td class="text-center">
						<!-- <button
							v-b-tooltip.hover.left
							@click="EditarDocumentos(lista,0)"
							type="button"
							data-backdrop="static"
							data-toggle="modal"
							data-target="#documentos"
							data-keyboard="false"
							class="mr-2 btn-icon"
							:title=" RolUsuario!='Monitoreo' && IdPerfil!=0 ? 'Editar' : 'Ver mas'"
							>
							<i
							:class="RolUsuario!='Monitoreo' && IdPerfil!=0 ? 'fa fa-pencil' : 'fa fa-eye'"></i>
						</button> -->

						<button
							v-b-tooltip.hover.left
							type="button"
							v-if="!lista.NombreArchivo.substr(lista.NombreArchivo.lastIndexOf('/')+1) == ''"
							@click="VerPDFDocumentos(lista.NombreArchivo)"
							data-backdrop="static"
							data-keyboard="false"
							class="mr-2 btn-icon"
							title="Ver PDF"
							>
							<i
							class="fa fa-file-pdf"></i>
						</button>

						<!-- <button
							v-if="RolUsuario!='Monitoreo' && IdPerfil!=0"
							v-b-tooltip.hover.left
							@click="BorrarDocumento(lista.IdPdf)"
							type="button"
							data-backdrop="static"
							data-keyboard="false"
							class="btn-icon-02 mr-2"
							title="Borrar"
							>
							<i 
							:id="'pdfEvidencia_'+1"
							class="fas fa-trash"></i>
						</button> -->
					</td>
					<!-- <td colspan="2"></td> -->
				</tr>
				<CSinRegistros :pContIF="Documentos.length" :pColspan="4" ></CSinRegistros>
			</template>
			<!-- BLOQUE DE REFACCIONES -->
			<template v-if="Botones.Refacciones" slot="header">
				<tr>
					<th>Nombre</th>
					<th>Marca</th>
					<th>Modelo</th>
					<th>Cantidad</th>
					<th>N° Parte</th>
					<th>O/R</th>
					<th>Reemplazo</th>
					<th>Archivos</th>
				</tr>
			</template>
			<template v-if="Botones.Refacciones" slot="body">
				<tr style="font-size:15px" v-for="(item,key,index) in Refacciones" :key="index">
					<td>{{ item.Descripcion }}</td>
					<td>{{ item.Marca }}</td>
					<td>{{ item.Modelo }}</td>
					<td>{{ item.Cantidad }}</td>
					<td>{{ item.NumParte }}</td>
					<td v-if="item.Tipo==1">Original</td>
					<td v-else>OEM</td>
					<td>{{ item.Periodo }}</td>
					<td>
						<button
						v-b-tooltip.hover.left
						type="button"
						v-if="!item.Archivo.substr(item.Archivo.lastIndexOf('/')+1) == ''"
						@click="VerPDFDocumentos(item.Archivo)"
						data-backdrop="static"
						data-keyboard="false"
						class="mr-2 btn-icon"
						title="Ver PDF"
						>
						<i
						class="fa fa-file-pdf"></i>
					</button>
					</td>
				</tr>
				<CSinRegistros :pContIF="Refacciones.length" :pColspan="8" ></CSinRegistros>
			</template>
			<!-- BLOQUE DE PROGRAMAS DE MANTENIMIENTO -->
			<template v-if="Botones.Programa" slot="header">
				<tr>
					<th>Título</th>
					<th>Descripción</th>
					<th class="text-right">Fecha de Registro</th>
					<th class="text-center">Archivo</th>
					<!-- <th class="text-right">
						<button
						v-b-tooltip.hover.left
							@click="EditarDocumentos(DocumentoVacio,1)"
							type="button"
							data-backdrop="static"
							data-toggle="modal"
							data-target="#documentos"
							data-keyboard="false"
							class="mr-2 btn-obs"
							title="Agregar"
							>
							<i
							class="fas fa-plus"></i>
						</button>
					</th> -->
				</tr>
			</template>
			<template v-if="Botones.Programa" slot="body">
				<tr v-for="lista,key,index in DocumentosMantenimiento" :key="index">
					<td>{{ lista.Titulo }}</td>
					<td>{{ lista.Descripcion }}</td>
					<td class="text-right"><i class="fa fa-calendar"></i> {{ $getCleanDate(lista.FechaAlta,false) }}</td>
					<td class="text-center">

						<!-- <button
							v-b-tooltip.hover.left
							@click="EditarDocumentos(lista,1)"
							type="button"
							data-backdrop="static"
							data-toggle="modal"
							data-target="#documentos"
							data-keyboard="false"
							class="mr-2 btn-icon"
							:title=" RolUsuario!='Monitoreo' && IdPerfil!=0 ? 'Editar' : 'Ver mas'"
							>
							<i
							:class="RolUsuario!='Monitoreo' && IdPerfil!=0 ? 'fa fa-pencil' : 'fa fa-eye'"></i>
						</button> -->

						<button
							v-b-tooltip.hover.left
							type="button"
							v-if="!lista.NombreArchivo.substr(lista.NombreArchivo.lastIndexOf('/')+1) == ''"
							@click="VerPDFDocumentos(lista.NombreArchivo)"
							data-backdrop="static"
							data-keyboard="false"
							class="mr-2 btn-icon"
							title="Ver PDF"
							>
							<i
							class="fa fa-file-pdf"></i>
						</button>

						<!-- <button
							v-if="RolUsuario!='Monitoreo' && IdPerfil!=0"
							v-b-tooltip.hover.left
							@click="BorrarDocumento(lista.IdPdf)"
							type="button"
							data-backdrop="static"
							data-keyboard="false"
							class="btn-icon-02 mr-2"
							title="Borrar"
							>
							<i 
							:id="'pdfEvidencia_'+1"
							class="fas fa-trash"></i>
						</button> -->
						
					</td>
					<!-- <td></td> -->
				</tr>
				<CSinRegistros :pContIF="DocumentosMantenimiento.length" :pColspan="4" ></CSinRegistros>
			</template>
			<template v-if="Botones.Ubicacion" slot="mapa">
				<div class="position-relative" style="bottom:30px;z-index:1000">
					<Cmapasearch :oLatLng="oLatLng" :Input="false"/>
				</div>
			</template>
					<!-- <template slot="detallehistorialequipo">
						<div class="row text-center">
							<div class="col-lg-12">
								<h1 class="breadcrumb-item active">Detalles del Equipo</h1>
							</div>
						</div>
						<div class="row mb-3">
							<div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
								<div class="shadow">
									<table class="table-01 text-nowrap">
										<thead>
											<th v-if="RolUsuario!='Monitoreo' && IdPerfil!=0" class="tw-2"></th>
											<th class="tw-2 text-center">Notas</th>
											<th v-if="RolUsuario!='Monitoreo' && IdPerfil!=0" class="tw-2 text-right">
												<button
												v-b-tooltip.hover.left
												@click="AgregarNota()"
												type="button"
												data-backdrop="static"
												data-toggle="modal"
												data-target="#notas"
												data-keyboard="false"
												class="mr-2 btn-obs"
												title="Agregar"
												>
												<i
												class="fas fa-plus"></i>
												</button>
											</th>
										</thead>
									</table>
									<textarea class="p-0 form-control" name="" id="blocnotasequipo" cols="30" rows="10" readonly v-model="Equipo.Observaciones"></textarea>
								</div>
							</div>
						</div>	
					</template> -->
				</Clist>

				
		<Modal :RolUsuario="RolUsuario" :Showbutton="RolUsuario!='Monitoreo' && RolUsuario!='' ? true : false" :poBtnSave="oBtnSave2" :NameModal="'Servicios'"  :size="'modal-lg'" :Nombre="'Servicios'">
			<template slot="Form">
				<Form 
				:RolUsuario="RolUsuario" :poBtnSave="oBtnSave2" :IdEquipo="Equipo.IdEquipo" :IdCliente="Cliente.IdCliente" :IdClienteS="ClienteSucursal.IdClienteS" :IdSucursal="Cliente.IdSucursal" :Cliente="Cliente.Nombre" :Econtacto="Cliente.Correo" :Direccion="Cliente.Direccion" :Contacto="Cliente.Contacto" 
				:Telefono="Cliente.Telefono"></Form>
			  </template>
		</Modal>
		<!-- <Modal :NameModal="'Servicios'" :size="size" :Nombre="NameForm" :Showbutton="false">
			<template slot="Form">
				<FormServicioManual :Equipo="Equipo"></FormServicioManual>
			</template>
		</Modal> -->
		<Modal :NameModal="'Historial'" :size="size" :Nombre="NameForm" :Showbutton="false">
			<template slot="Form">
				<DetHistory :Equipo="Equipo"></DetHistory>
			</template>
		</Modal>

		<Modal :RolUsuario="RolUsuario" :NameModal="'observaciones'" :size="size" :Nombre="'Observaciones'" :poBtnSave="oBtnSaveObservacion">
			<template slot="Form">
				<FormObservaciones :RolUsuario="RolUsuario" :poBtnSave="oBtnSaveObservacion" :oEquipo="Equipo.IdEquipo"></FormObservaciones>
			</template>
		</Modal>

		<Modal :pEmitSeccion="EmitSeccion1" :RolUsuario="RolUsuario" :NameModal="'documentos'" :size="size" :Nombre="TipoDocumento==0 ? 'Documentos del Equipo: '+Equipo.Nequipo : 'Programas de mantenimiento'" :poBtnSave="oBtnSave3">
			<template slot="Form">
				<FormDocumentos :RolUsuario="RolUsuario" :poBtnSave="oBtnSave3" :oEquipoP="Equipo"></FormDocumentos>
			</template>
		</Modal>

		<!-- <Modal :NameModal="'notas'" :size="size" :Nombre="'Notas'" :poBtnSave="oBtnSave">
			<template slot="Form">
				<FormNotas :poBtnSave="oBtnSave"></FormNotas>
			</template>
		</Modal> -->

		<Modal :Showbutton="false" :NameModal="'imagen'" :size="size" :Nombre="'Imagen'">
			<template slot="Form">
				<div class="form-row justify-content-center align-items-center">
					<img :src="ImagenAMostrar" alt="Aún no hay una imagen para mostrar" class="img-thumbnail">
				</div>
			</template>
		</Modal>

		<Modal :poBtnSave="oBtnSave4" :size="'modal-lg'" :Nombre="'Equipos'" >
            <template slot="Form" >
                <FormEquipos :poBtnSave="oBtnSave4" :oClienteSucursalP="ClienteSucursal">
                </FormEquipos>
            </template>
        </Modal>

	</div>
</template>
<script>
import Modal from "../../../components/Cmodal";
import Clist from "@/components/Clist.vue";
import Cbtnaccion from "@/components/Cbtnaccion.vue";
import DetHistory from "@/views/modulos/monitoreo//DetHistory.vue";
import Form from "@/views/modulos/monitoreo/FormServicioManual.vue";
import FormEquipos from "@/views/catalogos/equipos/Form.vue";
import CSinRegistros from "@/components/CSinRegistros";
import FormObservaciones from "@/views/modulos/monitoreo/FormObservaciones.vue";
import FormNotas from "@/views/modulos/monitoreo/FormNotas.vue";
import FormDocumentos from "@/views/catalogos/pdfequipo/Form.vue";
import Config from "@/helpers/VarConfig.js";
import SvgFiller from '@/vue-svg-filler'
import Cmapasearch from "@/components/Cmapasearch.vue";

export default {
	name: "listHistorialEquipo",
	props: ["ocliente", "oClienteSucursal", "oEquipo","rutaIcoEmp"],
	components: { 
		Modal, 
		Clist, 
		Cbtnaccion, 
		DetHistory, 
		Form,
		CSinRegistros, 
		FormObservaciones , 
		FormNotas,
		FormDocumentos, 
		FormEquipos,
		SvgFiller,
		Cmapasearch
	},
	data() {
		return {
			EmitSeccion1:"Documentos",
			Edicion:false,
			ImagenAMostrar:'',
			BndImagenEquipo:0,
			BndImagenEspecificaciones:0,
			Observacion:{
				IdObservacion:0,
				IdEquipo:0,
				Observacion:''
			},
			Observacion1:{
				IdObservacion:0,
				IdEquipo:0,
				Observacion:''
			},
			DocumentoVacio:{
				IdPdf:0,
				Titulo:'',
				NombreArchivo:'',
				FechaAlta:'',
			},
			HistorialEquipo:true,
			NameForm: "Información",
			FormName: "Historial", //Por si no es modal y queremos ir a una vista declarada en el router
			EsModal: true, //indica si es modal o no,
			CloseModal: true, //indica si el modal cierra o de lo contrario asignarle un evento al boton
			size: "",
			NameList: "Historial de Servicios",
			ListaHistorial: [],
			ClienteSucursal: {},
			Cliente: {},
			Equipo: {},
			regresar: true,
			btnadd: true,
			carga: false,
			Titulo:"Historial de Servicios",
			Nombre:"Servicios",
			Filtro: {
				Nombre: "",
				Placeholder: "Nombre..",
				TotalItem: 0,
				Pagina: 1,
				Entrada: 16,
				Show:true
			},
			ShowPager:true,
			oBtnSave:{
                isModal:true,
                disableBtn:false,
                toast:0,
				nombreModal:'observaciones'
            },
			oBtnSave2:{
                isModal:true,
                disableBtn:false,
                toast:0,
				nombreModal:'Servicios',
				ShowSave:true,
				ShowCancel:true,
            },
			oBtnSave3:{
                isModal:true,
                disableBtn:false,
                toast:0,
				nombreModal:'Documentos',
				EmitSeccion:'Documentos'
            },
			oBtnSave4:{
                isModal:true,
                disableBtn:false,
                toast:0,
				// nombreModal:'observaciones'
            },
			oBtnSaveObservacion:{
                isModal:true,
                disableBtn:false,
                toast:0,
				nombreModal:'Observacion',
				EmitSeccion:'Observacion'
            },
			
			ConfigLoad:{
				ShowLoader:true,
				ClassLoad:true,
			},
			RutaImagenEquipo:'',
			RutaImagenEspecificaciones:'',
			Refacciones:[],
			ValidElement:Config.validImage2m,
			Observacionesequipo:[],
			ShowBtnGuardarServicio:false,
			RolUsuario:"Admin",
			ImagenSeleccionada:'',
			ImagenSeleccionadaEspecificaciones:'',
			ContadorImagenEquipo:0,
			ContadorImagenEspecificaciones:0,
			ObjImagen:[],
			ObjImagenEspecificaciones:[],
			IdPerfil:0,
			Botones:{
				Historia:true,
				Observaciones:false,
				Documentos:false,
				Refacciones:false,
				Programa:false,
				Ubicacion:false
			},
			Documentos:[],
			DocumentosMantenimiento:[],
			UrlPdf:"",
			oLatLng:{
				Lat: 20.9673271,
                Lng: -89.6249853,
				//Lat:parseFloat(this.oClienteSucursal.Latitud),
				//Lng:parseFloat(this.oClienteSucursal.Longitud)
			},
			Programas:[],
			TipoDocumento:0,
			IdActivo:'',
			updEnProceso:false,
			RutaReporteManual:'',
			RutaEvidenciaManual:''
		};
	},
	methods: {
		async Lista() {
			this.ConfigLoad.ShowLoader = true;
			if (this.Botones.Historia) {
				await this.$http
				.get("monitoreo/historyequi", {
					params: {
						IdEquipo: this.Equipo.IdEquipo,
						Nombre: this.Filtro.Nombre,
						IdClienteS:this.ClienteSucursal.IdClienteS,
						Entrada: this.Filtro.Entrada,
						pag: this.Filtro.Pagina,
						RegEstatus: "A"
					}
				})
				.then(res => {
					this.ListaHistorial = res.data.historial;
					this.Filtro.Entrada = res.data.pagination.PageSize;
					this.Filtro.TotalItem = res.data.pagination.TotalItems;
					this.RutaReporteManual=res.data.RutaReporteManual;
					this.RutaEvidenciaManual=res.data.RutaEvidenciaManual;
					if (res.data.ContactoPropiedad!=null) {
						this.Cliente.Correo=res.data.ContactoPropiedad.Email;
						this.Cliente.Contacto=res.data.ContactoPropiedad.Nombre;
						this.Cliente.Telefono=res.data.ContactoPropiedad.Telefono;
					}
				}).finally(()=>{
					this.ConfigLoad.ShowLoader = false;
					this.updEnProceso=false;
				});
			}
			if (this.Botones.Observaciones) {
				this.$http.get('observacionequipo/get',{
				params:{
						IdEquipo:this.Equipo.IdEquipo,
						RegEstatus:'A'
					}
				}).then((response)=>{
					this.Observacionesequipo=response.data.data.observaciones;
				}).finally(()=>{
					this.ConfigLoad.ShowLoader = false;
					this.updEnProceso=false;
				});
			}
			if (this.Botones.Documentos) {
				await this.$http.get("manuales/get",{
						params:{
							IdActivo:this.Equipo.ActivoId,
							Tipo:1
						}
					}).then((res)=>{
						this.Documentos=res.data.data.documentos;
						// this.UrlPdf=res.data.data.UrlFile;
					}).finally(()=>{
						this.ConfigLoad.ShowLoader = false;
						this.updEnProceso=false;
					});
				// if (this.Equipo.CopiarDocumentos=='s') {
				// }
				// else{
				// 	await this.$http.get("pdfequipo/get",{
				// 	params:{
				// 		IdEquipo:this.Equipo.IdEquipo,
				// 		ActivoId:this.Equipo.ActivoId,
				// 		TipoDocumento:0,
				// 		Nombre: this.Filtro.Nombre,
				// 		Entrada: this.Filtro.Entrada,
				// 		pag: this.Filtro.Pagina,
				// 		RegEstatus: "A"
				// 	}
				// 	}).then((res)=>{
				// 		this.Documentos=res.data.data.pdfequipo;
				// 		this.UrlPdf=res.data.data.UrlFile;
				// 	}).finally(()=>{
				// 		this.ConfigLoad.ShowLoader = false;
				// 	});
				// }
			}
			if (this.Botones.Refacciones) {
				await this.$http.get("refacciones/get",{
				params:{
					IdActivo:this.Equipo.ActivoId,
				}
				}).then((res)=>{
					this.Refacciones=res.data.data.refacciones;
					// this.UrlPdf=res.data.data.UrlFile;
				}).finally(()=>{
					this.ConfigLoad.ShowLoader = false;
					this.updEnProceso=false;
				});
			}
			if (this.Botones.Programa) {
				await this.$http.get("manuales/get",{
				params:{
					IdActivo:this.Equipo.ActivoId,
					Tipo:2,
				}
				}).then((res)=>{
					this.DocumentosMantenimiento=res.data.data.documentos;
					this.UrlPdf=res.data.data.UrlFile;
				}).finally(()=>{
					this.ConfigLoad.ShowLoader = false;
					this.updEnProceso=false;
				});
			}
			
		},
		ServicioManual(){
			this.bus.$emit('NuevoServicioMonitoreo',this.ListaHistorial);
		},
		get_detalle(oDetalle) {
			// sessionStorage.setItem("Modal","Historial");
			// this.Nombre=sessionStorage.getItem("Modal");
			this.bus.$emit("Recovery", oDetalle);
			this.carga = true;
		},
		DescargarReporte(IdServicio){
			let spinner=document.getElementById(`pdfOrden_${IdServicio}`);
			spinner.setAttribute('class','fa fa-spinner fa-pulse fa-1x fa-fw');
			this.$http.get('reporte/servicio',
            {
                responseType: 'blob',
                params :
                    {
                        IdServicio:IdServicio,
                    }
            }).then( (response) => {

                let pdfContent = response.data;
                let file = new Blob([pdfContent], { type: 'application/pdf' });
                let fileUrl = URL.createObjectURL(file);
                window.open(fileUrl);
                spinner.setAttribute('class','fas fa-file-pdf fa-fw-m');

            });
		},
		DescargarEvidencia(IdServicio){
			// let spinner=document.getElementById(`pdfEvidencia_${IdServicio}`);
			// spinner.setAttribute('class','fa fa-spinner fa-pulse fa-1x fa-fw');
			// this.$http.get('reporte/servicioevidencia',
            // {
            //     responseType: 'blob',
            //     params :
            //         {
            //             IdServicio:IdServicio,
            //         }
            // }).then( (response) => {

            //     let pdfContent = response.data;
            //     let file = new Blob([pdfContent], { type: 'application/pdf' });
            //     let fileUrl = URL.createObjectURL(file);
            //     let pdfWindow=window.open(fileUrl);
			// 	pdfWindow.document.write(
			// 	"<iframe width='100%' height='100%' src='" +
			// 		fileUrl +
			// 		"'></iframe>"
			// );

            //     spinner.setAttribute('class','fas fa-file-pdf fa-fw-m');

            // });

			this.$router.push({name:'mon_evidencia',params:{IdServicio:IdServicio,Equipo:this.Equipo,Cliente:this.Cliente,ClienteS:this.ClienteSucursal,isMonitoreo:true,RutaIcoEmp:this.rutaIcoEmp}});
		},
		VerPDFDocumentos(NombreArchivo){
			let archivo = NombreArchivo;
			window.open(
				archivo,
				"_blank",
				"toolbar=1, scrollbars=1, resizable=1, width=" +1015 +", height=" +800
			);

		},
		DevolverServicioCompleto(oDetalle){
			if (this.RolUsuario!="Monitoreo" && this.RolUsuario!=0) {
				if(oDetalle.Origen.toLowerCase()=="despacho") {
					this.ShowBtnGuardarServicio=false;
					this.bus.$emit("MostrarBoton",this.ShowBtnGuardarServicio);
				}
			if(oDetalle.Origen.toLowerCase()=="monitoreo"){
					this.ShowBtnGuardarServicio=true;
					this.bus.$emit("MostrarBoton",this.ShowBtnGuardarServicio);
				}
			}
			let obj=Object.assign({},oDetalle);
			this.bus.$emit('Recuperar',obj,this.ListaHistorial);
			this.bus.$emit('RecuperarCliente',this.oClienteSucursal);
		},
		EditarImagenEquipo(){
			this.Equipo.ImagenEquipo = this.$refs.file.files[0];
			const image = this.$refs.file.files[0];
			const reader = new FileReader();
			var img = "";
			reader.readAsDataURL(image);

			reader.onload = e => {
				this.Img = e.target.result;
				this.readURL(this.Img);
			};
		},
		EditarObservaciones(lista){
			this.oBtnSave.nombreModal='observaciones';
			lista.IdEquipo=this.Equipo.IdEquipo;
			this.bus.$emit('NuevaObservacion',false,lista.IdObservacion);
		},
		EditarDocumentos(lista,Tipo){
			this.TipoDocumento=Tipo;
			if (Tipo==0) {
				this.bus.$emit('NewModal_'+this.EmitSeccion1,false,lista.IdPdf,Tipo);	
			}
			else{
				this.bus.$emit('NewModal_'+this.EmitSeccion1,false,lista.IdPdf,Tipo,this.DocumentosMantenimiento.length);
			}
		},
		AgregarNota(){
			this.oBtnSave.nombreModal='notas';
			this.bus.$emit('NuevaNota',false,this.Equipo.IdEquipo,this.Equipo.Observaciones);
		},
		ListaObservaciones(){
			// this.$http.get('observacionequipo/get',{
			// params:{
			// 		IdEquipo:this.Equipo.IdEquipo,
			// 		RegEstatus:'A'
			// 	}
			// }).then((response)=>{
			// 	this.Observacionesequipo=response.data.data.observaciones;
			// });
		},
		async ListaDocumentos(){
			// await this.$http.get("pdfequipo/get",{
			// 	params:{
			// 		IdEquipo:this.Equipo.IdEquipo,
			// 		RegEstatus: "A"
			// 	}
			// }).then((res)=>{
			// 	this.Documentos=res.data.data.pdfequipo;
			// 	this.UrlPdf=res.data.data.UrlFile;
			// })
		},
		GuardarImagenEquipo(){
			let formData=new FormData();
			let image=this.$refs.file.files[0];
			if (image!=undefined) {
				if (this.Equipo.ImagenEquipo!=null) {
					let index=this.Equipo.ImagenEquipo.lastIndexOf("/")+1;
					let cadena=this.Equipo.ImagenEquipo.substr(index);
					formData.set('ImagenPreviaEquipo','/'+cadena);	
				}
				formData.set('IdEquipo',this.Equipo.IdEquipo);
				formData.append('ImagenEquipo',image);
				this.RutaImagenEquipo=image.name;
				this.$http.post('equipo/imagenequipo/post',formData,{
					'Content-Type':'multipart/form-data'
				}).then((res)=>{
					this.$toast.success("Imagen guardada");
					this.bus.$emit('ActualizarImagenEquipo',res.data.data.equipos.ImagenEquipo);
					this.BndImagenEquipo=0;
				});
			}
			else{
				this.$toast.info("Tienes que subir una imagen");
			}
		},
		GuardarImagenEspecificaciones(){
			let formData=new FormData();
			let image=this.$refs.file2.files[0];
			if(image!=undefined){
				if (this.Equipo.ImagenEspecificaciones!=null) {
					let index=this.Equipo.ImagenEspecificaciones.lastIndexOf("/")+1;
					let cadena=this.Equipo.ImagenEspecificaciones.substr(index);	
					formData.set('ImagenPreviaEspecificaciones','/'+cadena);
				}
				formData.set('IdEquipo',this.Equipo.IdEquipo);
				formData.append('ImagenEspecificaciones',image);
				this.RutaImagenEquipo=image.name;
				this.$http.post('equipo/imagenespecificaciones/post',formData,{
					'Content-Type':'multipart/form-data'
				}).then((res)=>{
					this.$toast.success("Imagen guardada");
					this.bus.$emit('ActualizarImagenEspecificaciones',res.data.data.equipos.ImagenEspecificaciones);
					this.BndImagenEspecificaciones=0;
				});
			}
			else{
				this.$toast.info("Tienes que subir una imagen");
			}
		},
		CambiarImagenEquipo(target){
			if (this.ContadorImagenEquipo==0) {
				this.$uploadImagePreview(target,this.ValidElement, Array('Img','imagePreview'));
				if(this.$refs.file.files[0]!=undefined){
					this.ContadorImagenEquipo++;
					this.BndImagenEquipo=1;
					this.ObjImagen=this.$refs.file.files;
				}
				else{
					this.BndImagenEquipo=0;
				}
			}
			else{
				this.$uploadImagePreview(target,this.ValidElement, Array('Img','imagePreview'));
				if(this.$refs.file.files[0]==undefined){
					this.$refs.file.files=this.ObjImagen;
				}
				else{
					this.ObjImagen=this.$refs.file.files;
				}
			}
			
		},
		CambiarImagenEspecificaciones(target){
			if (this.ContadorImagenEspecificaciones==0) {
				this.$uploadImagePreview(target,this.ValidElement, Array('Img','ImagePreviewEspecificaciones'));
				if(this.$refs.file2.files[0]!=undefined){
					this.BndImagenEspecificaciones=1;
					this.ContadorImagenEspecificaciones++;
					this.ObjImagenEspecificaciones=this.$refs.file2.files;
				}
				else{
					this.BndImagenEspecificaciones=0;
				}
			}
			else{
				this.$uploadImagePreview(target,this.ValidElement, Array('Img','ImagePreviewEspecificaciones'));
				if(this.$refs.file2.files[0]==undefined){
					this.$refs.file2.files=this.ObjImagenEspecificaciones;
				}
				else{
					this.ObjImagenEspecificaciones=this.$refs.file2.files;
				}
			}
		},
		CambiarBanderaImagenEquipo(){
			this.BndImagenEquipo=1;
		},
		CambiarBanderaImagenEspecificaciones(){
			this.BndImagenEspecificaciones=1;
		},
		ZoomImagen(Imagen,camino){
			if (this.RolUsuario!="Monitoreo" && this.IdPerfil!=0) {
				switch (camino) {
					case 1:
						if (this.$refs.file.files[0]!=undefined) {
							let ImagenPreview=document.getElementById("imagePreview");
							const Estilos=window.getComputedStyle(ImagenPreview);
							let CadenaBase=Estilos.backgroundImage;
							let Url=CadenaBase.slice(5,-2);
							this.ImagenAMostrar=Url;
						}
						else{
							this.ImagenAMostrar=Imagen;
						}
						break;
					case 2:
						if (this.$refs.file2.files[0]!=undefined){
							let ImagenPreview=document.getElementById("ImagePreviewEspecificaciones");
							const Estilos=window.getComputedStyle(ImagenPreview);
							let CadenaBase=Estilos.backgroundImage;
							let Url=CadenaBase.slice(5,-2);
							this.ImagenAMostrar=Url;
						}
						else{
							this.ImagenAMostrar=Imagen;
						}
						break;
				}
			}
			else{
				switch (camino) {
					case 1:
						this.ImagenAMostrar=this.Equipo.ImagenEquipo;
						break;		
					case 2:
					this.ImagenAMostrar=this.Equipo.ImagenEspecificaciones;
						break;
				}
			}
		},
		CambiarPestania(index){
			let Botones=this.Botones;
			Object.keys(Botones).forEach(function(key,ind){
				if (key==index) {
					Botones[key]=true;
				}
				else{
					Botones[key]=false;
				}
				// console.log(index)
			});
			if (this.Botones.Historia && this.Filtro.Pagina!=1) {
				this.Filtro.Pagina=1;
				this.Lista();
			}
			if (this.Botones.Observaciones && this.Observacionesequipo.length==0) {
				this.Lista();
			}
			if (this.Botones.Documentos && this.Documentos.length==0) {
				this.Lista();
			}
			if (this.Botones.Refacciones && this.Refacciones.length==0) {
				this.Lista();
			}
			if (this.Botones.Programa && this.DocumentosMantenimiento.length==0) {
				this.Lista();
			}
			if (this.Botones.Ubicacion) {
				this.ConfigLoad.ShowLoader=false;
			}
			if (!this.Botones.Historia) {
				this.Filtro.Show=false;
				this.ShowPager=false;
			}
			else{
				this.Filtro.Show=true;
				this.ShowPager=true;
			}
		},
		Borrar(Id){
			this.$swal({
                title:'¿Está seguro de querer eliminar este dato?',
                text:'No se podrá revertir esta acción',
                type:'warning',
                showCancelButton:true,
                confirmButtonText:'si',
                cancelButtonText:'no, mantener',
                showCloserButton:true,
                ShowLoaderOnConfirm:true,
            }).then((result=>{
                if(result.value){
                    this.$http.delete(
                        'observacionequipo/post/'+Id
                    ).then((res=>{
						this.$toast.success('Informacion eliminada');
                        this.Lista();
                    }))
                }
            }))
		},
		BorrarDocumento(Id){
			this.$swal({
                title:'¿Está seguro de querer eliminar este dato?',
                text:'No se podrá revertir esta acción',
                type:'warning',
                showCancelButton:true,
                confirmButtonText:'si',
                cancelButtonText:'no, mantener',
                showCloserButton:true,
                ShowLoaderOnConfirm:true,
            }).then((result=>{
                if(result.value){
                    this.$http.delete(
                        'pdfequipo/'+Id
                    ).then((res=>{
						this.$toast.success('Informacion eliminada');
                        this.Lista();
                    }))
                }
            }))
		},
		BorrarServicioManual(Id){
			this.$swal({
                title:'¿Está seguro de querer eliminar este dato?',
                text:'No se podrá revertir esta acción',
                type:'warning',
                showCancelButton:true,
                confirmButtonText:'si',
                cancelButtonText:'no, mantener',
                showCloserButton:true,
                ShowLoaderOnConfirm:true,
            }).then((result=>{
                if(result.value){
                    this.$http.delete(
                        'serviciosimple/'+Id
                    ).then((res=>{
						this.$toast.success('Informacion eliminada');
                        this.Lista();
                    }))
                }
            }))
		},
		RecuperarIdActivo(){
			this.$http.get('activos/recovery',{
				params:{
					IdActivo:this.Equipo.ActivoId
				}
			}).then((res)=>{
				this.IdActivo=res.data.data.Activo.CodigoActivo
			});
		},
		EditarEquipo(Id){
			this.bus.$emit('Nuevo',false,Id)
		},
		BorrarEquipo(Id){
			this.$swal({
                    title: 'Esta seguro que desea eliminar este dato?',
                    text: 'No se podra revertir esta acción',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No, mantener',
                    showCloseButton: true,
                    showLoaderOnConfirm: true
                    }).then((result) => {
                    if(result.value) {
                         this.$http.delete(
                            'equipos/' + Id
                        ).then( (res) => {
                            this.$toast.success('Información eliminada');
							this.bus.$emit("Regresar");
                            // this.Lista();
                        });
                    }
                });
		},
		DescargarQR(Equipo){
                this.$http.get('reporte/equipoetiquetascanning', {
                    responseType: 'blob',
                    params: {
                        IdEquipo:Equipo.IdEquipo,
                    }
                }).then( (response) => {
    
                    let pdfContent = response.data;
                    let file = new Blob([pdfContent], { type: 'application/pdf' });
                    let fileUrl = URL.createObjectURL(file);
    
                    window.open(fileUrl);
    
                });
        },
		DefinirClaseActivo(Status){
            let Clase="";
            switch (Status) {
                case "Operando":
                    Clase="circuloStatusOperando";
                    break;
                case "En Observacion":
                    Clase="circuloStatusObservacion";
                    break;
                case "Fuera de Servicio":
                    Clase="circuloStatusFueraServicio";
                    break;
                case "En Servicio":
                    Clase="circuloStatusServicio";
                    break;
                default:
                    Clase="circuloStatusDefault";
                    break;
            }
            return Clase;
        },
		MostrarFolio(item){
			if (item.Indicador!=null) {
				let Palabra='';
				switch (item.TipoInsercion) {
					case "1":
						Palabra='Offline'
						break;
					case "2":
						Palabra='Antiguo'
						break;
				}
				if (item.Folio!='') {
					return Palabra + ' - ' + item.Folio;
				}
				else{
					return Palabra;
				}
			}
			else{
				return item.Folio;
			}
		}
	},
	created() {
		this.bus.$off("Regresar");

		if (this.ocliente != undefined) {
			sessionStorage.setItem("ocliente", JSON.stringify(this.ocliente));
			sessionStorage.setItem(
				"oClienteSucursal",
				JSON.stringify(this.oClienteSucursal)
			);
			sessionStorage.setItem("oEquipo", JSON.stringify(this.oEquipo));
			sessionStorage.setItem("Modal",this.Nombre);
		}

		this.ClienteSucursal = JSON.parse(
			sessionStorage.getItem("oClienteSucursal")
		);
		this.Cliente = JSON.parse(sessionStorage.getItem("ocliente"));
		this.Equipo = JSON.parse(sessionStorage.getItem("oEquipo"));

		this.NameList = this.Equipo.Nequipo + " | " + this.NameList;
		this.Titulo=this.Equipo.Nequipo+" | "+this.Titulo;
		// this.Nombre=sessionStorage.getItem("Modal");

		this.bus.$on("Regresar", () => {
			if (sessionStorage.getItem('propiedad')!==null) {
				this.$router.push({
					name: "mon_equipo",
					params: { obj: this.ClienteSucursal, objCliente: this.Cliente , rutaIcoEmp:this.rutaIcoEmp,return:false}
				});
			}
			else{
				this.$router.push({
					name: "mon_equipo",
					params: { obj: this.ClienteSucursal, objCliente: this.Cliente , rutaIcoEmp:this.rutaIcoEmp}
				});
			}
			
		});
		this.bus.$off('ListaObservaciones');
		this.bus.$on('ListaObservaciones',()=>{
			this.ListaObservaciones();
		});
		this.bus.$off('ListaDocumentos');
		this.bus.$on('ListaDocumentos',()=>{
			this.ListaDocumentos();
		})
		this.bus.$off('ActualizarNota');
		this.bus.$on('ActualizarNota',(observaciones)=>{
			this.Equipo.Observaciones=observaciones;
			sessionStorage.setItem("oEquipo",JSON.stringify(this.Equipo));
		});
		this.bus.$off('ActualizarImagenEquipo');
		this.bus.$on('ActualizarImagenEquipo',(NombreImagen)=>{
			this.Equipo.ImagenEquipo=NombreImagen;
			sessionStorage.setItem("oEquipo",JSON.stringify(this.Equipo));
			// this.$refs.file.files=undefined;
			this.ImagenAMostrar='';
			this.ContadorImagenEquipo=0;
		});
		this.bus.$off('ActualizarImagenEspecificaciones');
		this.bus.$on('ActualizarImagenEspecificaciones',(NombreImagen)=>{
			this.Equipo.ImagenEspecificaciones=NombreImagen;
			sessionStorage.setItem("oEquipo",JSON.stringify(this.Equipo));
			// this.$refs.file2.files=undefined;
			this.ImagenAMostrar='';
			this.BndImagenEspecificaciones=0;
			this.ContadorImagenEspecificaciones=0;
		});

		this.bus.$off('ActualizarEquipo');
		this.bus.$on('ActualizarEquipo',(equipo)=>{
			this.updEnProceso=true;
			this.Equipo=equipo;
			sessionStorage.setItem("oEquipo",JSON.stringify(equipo));
			this.Titulo=this.Equipo.Nequipo+" | Historial de Servicios";
			this.Refacciones=[];
			this.Documentos=[];
			this.DocumentosMantenimiento=[];
			this.Programas=[];
			this.Lista();
			this.RecuperarIdActivo();
			
		});
	
		this.bus.$off('Listar');
		this.bus.$on('Listar',()=>{
			this.Lista();
		});
		let usuario=JSON.parse(this.RolUsuario=sessionStorage.getItem("user"));
		this.RolUsuario=usuario.Perfil;
		this.IdPerfil=usuario.IdPerfil;
		
	},
	mounted() {
		this.Lista();
		this.ListaObservaciones();
		this.ListaDocumentos();
		this.RecuperarIdActivo();
	},
	computed:{
        setLocation: function () {
            this.oLatLng.Lng  = parseFloat(this.ClienteSucursal.Longitud);
            this.oLatLng.Lat  = parseFloat(this.ClienteSucursal.Latitud);
            return this.oLatLng;
        }
    },
	destroyed() {
		sessionStorage.removeItem("ocliente");
		sessionStorage.removeItem("oClienteSucursal");
		sessionStorage.removeItem("oEquipo");
		// sessionStorage.removeItem("Modal");
	}
};
</script>
