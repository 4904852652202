<template>
	<div>
		<input type="hidden" :value="RegresaValor" v-if="showInput">

		<div  v-if="showInput" class="row">
			<div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-10">
				<gmap-autocomplete class="form-control" :options="{fields: ['geometry', 'formatted_address', 'address_components']}" @place_changed="setPlace"/>
			</div>

			<div v-if="showInput" class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-2 text-right">
				<button type="button" class="btn btn-sm btn-info" @click="addMarker">Buscar</button>
			</div>
		</div>
		<br v-if="showInput">

		<gmap-map :center="center" :zoom="18" style="width:100%;  height: 400px;">
			<gmap-marker :position="center" :draggable="IsDraggable" @drag="updateCoordinates"/>
      <gmap-marker ref="marker" v-for="(item, index) in arreglodinamico" :key="index" :position="item.position"/>
		</gmap-map>

  </div>
</template>

<script>
export default {
  name: "GoogleMap",
  props:['oLatLng','Input','pIsDraggable','pArreglo','pOrigen','rutatrab'],
  data() {
    return {

      // default to Montreal to keep it simple
      // change this to whatever makes sense

      center: {
		    lat: parseFloat(this.oLatLng.Lat),
		    lng: parseFloat(this.oLatLng.Lng)
      },
      IsDraggable:true,
      markers: [],
      markers2:[],
      places: [],
      ruta:'',
      currentPlace: null,
      infoWindow: {
        position: {
		      lat: 0,
		      lng: 0
		    },
        open: false,
        template: ''
      },
      showInput:true
    };
  },

  mounted() {

    // this.geolocate();

    this.markers.push({ position: this.center });

    this.addMarker();
  },

  methods: {
    // receives a place object via the autocomplete component
    setPlace(place) {
      this.currentPlace = place;
    },
    addMarker() {

      if (this.currentPlace) {
        //#endregionDatos del lugar
        //console.log(this.currentPlace);
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng()
        };

        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
      }
    },
    // geolocate: function() {
    //   navigator.geolocation.getCurrentPosition(position => {
    //     this.center = {
    //       lat: position.coords.latitude,
    //       lng: position.coords.longitude
    //     };
    //   });

    // },
    updateCoordinates(location) {

       this.oLatLng.Lat=location.latLng.lat();
        this.oLatLng.Lng=location.latLng.lng();
            /*this.center = {
                lat: location.latLng.lat(),
                lng: location.latLng.lng(),
            };/*
    }, openInfoWindowTemplate (item) {
        /*this.setInfoWindowTemplate(item)
        this.infoWindow.position = this.getCoordinates(item)
        this.infoWindow.open = true*/
    },
    actualCordenadas()
    {

        this.center.lat = parseFloat( this.oLatLng.Lat);
        this.center.lng =parseFloat( this.oLatLng.Lng);
    },

    actualCordenadas2()
    {

        this.center.lat =  19.43472888705915;
        this.center.lng = -99.13303711188354;

        this.oLatLng.Lat=  19.43472888705915;
        this.oLatLng.Lng= -99.13303711188354;

        this.addMarker();
    }
  },computed: {
      RegresaValor(){
        this.oLatLng.Lat=this.center.lat;
        this.oLatLng.Lng=this.center.lng;
        return this.center;
      },
      arreglodinamico()
    {
      if (this.pOrigen=='Sucursal') {
        let marcadores =  this.Arreglo;
        this.markers2= this.Arreglo;
        this.ruta=this.rutatrab;
        setTimeout(this.Boud, 1000); 
        return marcadores;
      }
      else{
        return [];
      }
    }
  },
  created() {

    if (this.Input!=undefined) {
      this.showInput=this.Input
    }
    if (this.pIsDraggable!=undefined) {
      this.IsDraggable=this.pIsDraggable;
    }

      this.bus.$on("ActualC",data  =>{

            this.actualCordenadas();
      });

      this.bus.$on("actualCordenadas2",data  =>{

            this.actualCordenadas2();
      });
  },
};
</script>

<style >
.pac-container{
  z-index: 10000;
}
</style>
